import { render, staticRenderFns } from "./QueryDocumentBox.vue?vue&type=template&id=4dcb3b0d&scoped=true&"
import script from "./QueryDocumentBox.vue?vue&type=script&lang=js&"
export * from "./QueryDocumentBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dcb3b0d",
  null
  
)

export default component.exports