<template>

  <div class="container-fluid">

    <BannerComponent/>

    <div class="row" v-if="no_id">
        <QueryDocumentBox @fetchSibils=fetchSibils />
    </div>

    <div class="row" v-show="showResultsClick" v-if="no_id">
      <b-tabs content-class="mt-3" justified pills>
        <ResultsSearchCollection ref="fetchResults" :collection_name="user_query.collection_search" :collection_title="collections[user_query.collection_search]"/>
        </b-tabs>
    </div>

    <div class="row" v-show="showResultsUrl" v-else>
    
      <ResultsSearchDocument class="results" v-for="(document_id, index) in user_query.ids.split(',')" :key="index+'_'+document_id"  :document_id="document_id" :collection_name="user_query.collection" :collection_title="user_query.collection" />

    </div>

    <div class="row">
      <div class="issue-container">
        <a :href="get_report_link" target="_blank">Bug report</a> | <a :href="get_feature_request_link" target="_blank">Feature request</a>            
      </div>
    </div>

  </div>

</template>

<script>

import BannerComponent from '@/components/BannerComponent.vue'
import ResultsSearchCollection from '@/components/ResultsSearchCollection.vue'
import ResultsSearchDocument from '@/components/ResultsSearchDocument.vue'
import QueryDocumentBox from '@/components/QueryDocumentBox.vue'
import { mapState, mapActions } from 'vuex'


export default {
  name: 'FetchPage',
  components: {
    BannerComponent,
    ResultsSearchCollection,
    ResultsSearchDocument,
    QueryDocumentBox
  },
  data() {
        return {
            showResultsClick: false,
            showResultsUrl: false,
            collection: 'medline'
        }
  },
  computed: {
        ...mapState(['theme_color', 'user_query', 'collections']),
        no_id(){
          if ('ids' in this.$route.params && this.$route.params.ids.length > 0){
            return false
          }
          return true
        },
        get_report_link(){
          var url = window.location.href
          var params = (new URL(document.location)).searchParams;
          return "https://github.com/sibils/community/issues/new?template=bugreport.yaml&labels=bug,biodiversityPMC&service=Biodiversity PMC&url="+encodeURI(url+params)
        },
        get_feature_request_link(){
          return "https://github.com/sibils/community/issues/new?template=featurerequest.yaml&labels=enhancement,biodiversityPMC&service=Biodiversity PMC"
        },
  },
  methods:{
    ...mapActions(['updateIds', 'updateCollection', 'updateCollectionSearch', 'updateModeExec', 'updateDocSource']),
    fetchSibils(){
      this.$matomo?.trackEvent("Fetch", this.user_query.ids+";"+this.collection);
      this.showResultsClick = false
      this.updateCollectionSearch(this.user_query.collection)
      this.updateModeExec("doc")
      //this.$router.push({path:"/collections/"+this.user_query.collection+"/"+this.user_query.ids }).catch(()=>{});
      this.$refs.fetchResults.searchByIds(this.user_query.ids.split(","))
      this.showResultsClick = true
    },
    queryByURL(){
      this.$matomo?.trackEvent("Fetch", this.user_query.ids+";"+this.user_query.collection);
      window.document.title = this.user_query.ids
      this.updateCollectionSearch(this.user_query.collection)
      this.showResultsUrl = true
    }
  },
  beforeMount() {
    if ('ids' in this.$route.params){
      this.updateIds(this.$route.params.ids)
    }
    if ('collection' in this.$route.params){
      this.updateCollection(this.$route.params.collection)
    }
  },
  mounted(){
    if (this.user_query.ids.length > 0){
      this.queryByURL()
    }
  },
}

</script>

<style scoped lang="scss">

    .container-fluid{
        padding-right: 0px;
        padding-left: 0px;
        margin-right: auto;
        margin-left: auto;
    }

    .row {
        padding: 50px;
        margin-left: 0;
        margin-right: 0;
    }

    .results {
      margin: 10px;
      text-align: left;
      border: 1px solid #ddd;
      padding: 15px 15px 0 15px;
    }

</style>