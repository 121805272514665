<template>

    <div class="parameters-container">

      <div class="parameter-container">
        <label>Enter an identifier:</label><br />
        <select name="collection" id="collection" v-model="user_query.collection">
          <option v-for="(value, key, index) in collections" :key="'collection'+index" :value="key">{{ value }}</option>
        </select>
        <input type="text" required v-model.trim="user_query.ids" placeholder="identifier"/>
        
      </div>
            
    </div>

</template>


<script>
import { mapState } from 'vuex'

    export default {
      name: 'QueryDocumentSimple',
      components: {
      },
      data() {
        return {

        };
      },
      computed: {
        ...mapState(['user_query', 'collections']),

      },
    }

</script>


<style scoped lang="scss">

    .parameters-container {
        padding-bottom: 20px;
        text-align: left
    }
    .parameter-container {
        padding: 0 0 20px 0;
    }

    label {
        font-weight: bold;
    }

    input[type="text"] {
      width: 80%;
      padding-left: 10px;
      background-color: #fff;
      background-size: 30px;
      height: 40px;
      border: 1px solid grey;
      border-radius: 5px;
      font-size: 1.2em;
   }

   input[type="checkbox"] {

      margin-right: 10px;
      border: 1px solid grey;
   }

   select {
    background-color: #fff;
    width: 20%;
    border: 1px solid grey;
      border-radius: 5px;
      height: 40px;
   }


</style>
