<template>

    <div class="component-container query-container">

        <h2>Search a document</h2>

            <QueryDocumentSimple/>

            <ExecuteButton :disabled="to_disable" @click="fetchSibils()" button_text="Search" />

    </div>

</template>


<script>
import QueryDocumentSimple from '@/components/QueryDocumentSimple.vue'
import ExecuteButton from '@/components/ExecuteButton.vue'
import { mapState } from 'vuex'

    export default {
      name: 'QuerySearchBox',
      components: {
        QueryDocumentSimple,
        ExecuteButton,
      },
      data() {
        return {
        };
      },
      computed: {
        ...mapState(['user_query']),
        to_disable(){
            if (this.user_query.ids == ""){
                return true
            }
            return false
        },
      },
      methods:{
        fetchSibils(){
            this.$emit('fetchSibils')
        },
      },
    }

</script>


<style scoped lang="scss">

   

</style>
